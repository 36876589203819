html,
body {
  height: 100%;
  width: 100%;
}

body {
  @include sans-serif-font;
}

hr {
  border-color: $theme-primary;
  border-width: 3px;
  max-width: 50px;
}

hr.light {
  border-color: white;
}

a {
  @include transition-all;
  color: $theme-primary;

  &:hover,
  &:focus {
    color: darken($theme-primary, 10%);
    text-decoration: none;
  }

  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include sans-serif-font;
}

p, li {
  font-size: 18px;
  line-height: 2;
  margin-bottom: 20px;
  font-weight: 300;
  letter-spacing: 1.5px;
}

.bg-primary {
  background-color: $theme-primary;
}

.bg-secondary {
  background-color: $theme-secondary;
}

.bg-dark {
  background-color: $theme-dark;
  color: white;
}

.text-faded {
  color: rgba(white, .7);
}

section {
  padding: 100px 0;
}

.no-bottom-padding {
  padding-bottom: 0;
}

aside {
  padding: 50px 0;
}

.no-padding {
  padding: 0;
}

$navbar-height: 150px; // Adjust this value to match your actual navbar height
$hiring-bar-height: 50px; // Height of the hiring bar
$transition-duration: 0.3s;

.navbar-default {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $navbar-height; // Set the height of the navbar
  background-color: white;
  border-color: rgba($theme-dark, 0.5);
  z-index: 2000; // Ensure it's on top of everything else

  .navbar-header .navbar-brand {
    color: $theme-primary;
    font-weight: 700;
    text-transform: uppercase;

    &:hover,
    &:focus {
      color: darken($theme-primary, 10%);
    }
  }

  .navbar-collapse {
    background-color: white;
    max-height: unset;
    text-align: right;
    padding-right: 15px;
    width: 300px;
    float: right;
  }

  @media (max-width: 768px) {
    .img-responsive {
      max-width: 100% !important;
    }

    .navbar-brand {
      max-width: 80%;
    }
  }

  @media (min-width: 1281px) {
    .navbar-collapse {
      background-color: unset;
      text-align: unset;
      padding-right: unset;
      width: unset;
      float: unset;
    }
  }

  .nav {
    margin-top: 50px;
    text-align: center;

  }

  .nav > li > a,
  .nav > li > a:focus {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    color: $theme-dark;

    &:hover {
      color: $theme-primary;
    }
  }

  .navbar-brand {
    margin-top: 20px;
    vertical-align: center;
    height: 100%;
  }

  .navbar-brand img {
    height: 76px;
    margin: 0;
  }
}

#hiring {
  position: sticky; // Make the hiring bar fixed under the navbar
  top: $navbar-height; // Position it directly below the navbar
  left: 0;
  right: 0;
  background-color: $theme-primary;
  z-index: 1030; // Just below the navbar
  padding: 10px 0;
  transition: transform $transition-duration ease-in-out;

  &.hide {
    transform: translateY(-100%); // Slide up to hide when scrolling down
  }

  .hiring-header {
    margin: 0;
    font-size: 1.75rem;
    text-align: center;
    color: white; // Adjust text color to match your theme
  }
}

/* Force navbar to collapse at 1281px */
@media (max-width: 1281px) {
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
    margin-top: 54px;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-collapse.collapse.in {
    display: block !important;
  }
  .collapsing {
    overflow: hidden;
  }
}

/* Reset for larger screens */
@media (min-width: 1281px) {
  .navbar-toggle {
    display: none;
  }
  .navbar-collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
}

// Homepage Header
header {
  background-position: 48% 90%;
  margin-top: $navbar-height; // Adjust this value to match your actual navbar height
  position: relative;
  width: 100%;
  min-height: 100%;
  @include background-cover;
  background-image: url('../img/starfish_family.jpg');
  color: #e2614e;

  .header-content {
    position: relative;
    text-align: left;
    padding: 20px 30px;
    width: 100%;

    .header-content-inner {
      max-width: 70%;

      h1 {
        @include nexa-font-bold();
        color: $theme-primary;
        font-weight: 600;
        letter-spacing: 3px;
        line-height: 1.25;
        text-align: left;
        font-size: 6rem !important;

        span {
          color: $theme-secondary;
        }
      }

      hr {
        margin: 30px auto;
      }

      p {
        font-weight: 300;
        color: white;
        font-size: 16px;
        margin-bottom: 50px;
      }

      img {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @media (max-width: 768px) {
    .header-content {
      padding: 20px 30px;

      .header-content-inner {
        max-width: 80%;

        h1 {
          font-size: 5rem !important;
        }
      }
    }
  }

  @media (max-width: 500px) {
    background-position: 52% 90%;
    .header-content {
      .header-content-inner {
        h1 {
          font-size: 4rem !important;
        }
      }
    }
  }

  @media (min-width: 1281px) {
    background-position: 48% 90%;
    min-height: 100%;
    background-size: unset;
    @include background-cover;

    .header-content {
      position: absolute;
      top: $navbar-height + 20px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      padding: 0 30px;
      width: 100%;

      .header-content-inner {
        max-width: 768px;
        //margin-top: 0;
        background: unset;
      }
    }
  }
}

// Sections
.section-heading {
  margin-top: 0;
}

.heading-white {
  color: white;
}

#introduction {
  p {
    text-align: left;
    font-weight: 700;

    font-size: 20px;
    line-height: 1.5;
    letter-spacing: 1px;
  }
}

.certification-box {
  max-width: 400px;
  margin: 26px auto 0;
  padding-bottom: 0;
  @media (min-width: 992px) {
    margin: 20px auto 0;
  }

  p {
    margin-bottom: 0;
  }

  img {
    display: unset;
  }
}

#services {
  .service-box {
    margin: 50px auto 0;
    @media (min-width: 992px) {
      margin: 20px auto 0;
    }

    h3 {
      text-align: left;
      padding-bottom: 50px;
      padding-left: 40px;
    }

    p {
      margin-bottom: 0;
      text-align: left;
      padding-left: 40px;
    }

    ul {
      font-size: 16px;
      line-height: 1.5;
      list-style: none;
      margin-top: 24px;
      text-align: left;
      padding-left: 0;
    }

    li {
      margin-bottom: 10px;
      padding-left: 50px;
    }

    li:before {
      content: "+";
      color: $theme-primary;
      font-weight: 800;
      font-size: 20px;
      margin-right: 4px;
    }
  }

  .wrapper {
    width: 300px;
    height: 200px;
    display: table;
    border: 5px solid #111;
    margin: 50px auto 10px auto;
    padding: 20px;
    position: relative;
    //text-align: left;

    &:after,
    &:before {
      content: '';
      position: absolute;
      font-family: fontAwesome;
      font-size: 32px;

      background: #fff;
    }

    &:after {
      content: '\f10d';
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);
      box-shadow: 10px 10px 0 #fff;
    }

    &:before {
      content: '\f10e';
      bottom: 0;
      right: 0;
      transform: translate(50%, 50%);
      box-shadow: -10px -10px 0 #fff;
    }
  }

  @media (min-width: 768px) {
    .wrapper {
      width: 90%;
    }
  }

  .title,
  .description {
    display: table-cell;
    width: 90%;
    vertical-align: middle;

    p {
      margin-left: 0;
      padding-left: 0;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.5;
    }
  }

  .description {
    font-size: 14px;
  }

  .title {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    padding: 0 20px 0 0;

    small {
      display: block;
      font-size: 25px;
      text-align: right;
    }
  }

  .border-radius {
    border-radius: 30px;
  }

  .text-right {
    text-align: right;
  }

  .border-theme-primary {
    border: 2px solid $theme-secondary;

    &:after,
    &:before {
      color: $theme-primary;
    }
  }

  .border-theme-secondary {
    border: 2px solid $theme-secondary;

    &:after,
    &:before {
      color: $theme-secondary;
    }
  }

  .quoter {
    font-size: 20px;
    font-weight: 700;
    margin: 30px auto !important;
    padding-left: 20px !important;
    width: 90%;
  }
}

.container-narrow {
  max-width: 960px;
  margin: 0 auto;
}

#portfolio {
  h3 {
    text-align: left;
    padding-bottom: 20px;
    padding-left: 40px;
  }

  .portfolio-box {
    display: block;
    margin: 0 auto;

    h4 {
      text-align: left;
      margin: 0;
      padding-bottom: 20px;
      padding-left: 40px;
      font-weight: 600;
    }

    .project-description {
      margin-bottom: 20px;
      padding-left: 40px;
    }

    .background-primary {
      background: rgba($theme-primary, .9);
    }

    .background-secondary {
      background: rgba($theme-secondary, .9);
    }

    &:hover {
      .portfolio-box-caption {
        opacity: 1;
      }
    }

    @media (min-width: 768px) {
      .portfolio-box-caption {
        .portfolio-box-caption-content {
          .project-category {
            font-size: 16px;
          }

          .project-name {
            font-size: 22px;
          }
        }
      }
    }
  }
}

#testimonials {
  h3 {
    text-align: left;
    padding-left: 40px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center items on the main axis */
  }

  .wrapper {
    width: 300px;
    height: 200px;
    display: table;
    border: 5px solid #111;
    margin: 30px auto 50px auto;
    padding: 20px;
    position: relative;

    &:after,
    &:before {
      content: '';
      position: absolute;
      font-family: fontAwesome;
      font-size: 32px;

      background: #fff;
    }

    &:after {
      content: '\f10d';
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);
      box-shadow: 10px 10px 0 #fff;
    }

    &:before {
      content: '\f10e';
      bottom: 0;
      right: 0;
      transform: translate(50%, 50%);
      box-shadow: -10px -10px 0 #fff;
    }
  }

  .img-ce {
    display: none;
  }

  @media (min-width: 768px) {
    .wrapper {
      width: 100%
    }

    .img-ce {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 60%;
      // center image vertically
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .title,
  .description {
    display: table-cell;
    width: 80%;
    vertical-align: middle;

    p {
      padding-left: 0;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.5;
    }
  }

  .description {
    font-size: 14px;
  }

  .title {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    padding: 0 20px 0 0;

    small {
      display: block;
      font-size: 25px;
      text-align: right;
    }
  }

  .border-radius {
    border-radius: 30px;
  }

  .text-right {
    text-align: right;
  }

  .border-theme-primary {
    border: 2px solid $theme-secondary;

    &:after,
    &:before {
      color: $theme-primary;
    }
  }

  .border-theme-secondary {
    border: 2px solid $theme-primary;

    &:after,
    &:before {
      color: $theme-secondary;
    }
  }


}

#about-us {
  h2 {
    margin-top: 50px;
  }

  .circle {
    border-radius: 50%;
    width: 300px;
    height: 300px;
    margin: 0 auto;
    display: block;
    object-fit: cover;
    border: white 15px solid;
  }
}

#leadership {
  span {
    font-weight: 700;
  }


  p {
    margin-left: 30px;
  }

  .wrapper {
    width: 300px;
    height: 200px;
    display: table;
    border: 5px solid #111;
    margin: 50px auto 10px auto;
    padding: 20px;
    position: relative;

    &:after,
    &:before {
      content: '';
      position: absolute;
      font-family: fontAwesome;
      font-size: 32px;

      background: #fff;
    }

    &:after {
      content: '\f10d';
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);
      box-shadow: 10px 10px 0 #fff;
    }

    &:before {
      content: '\f10e';
      bottom: 0;
      right: 0;
      transform: translate(50%, 50%);
      box-shadow: -10px -10px 0 #fff;
    }
  }

  @media (min-width: 768px) {
    .wrapper {
      width: 90%;
    }
  }

  .title,
  .description {
    display: table-cell;
    width: 90%;
    vertical-align: middle;

    p {
      padding-left: 0;
      margin-left: 0;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.5;
    }
  }

  .description {
    font-size: 14px;
  }

  .title {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    padding: 0 20px 0 0;

    small {
      display: block;
      font-size: 25px;
      text-align: right;
    }
  }

  .border-radius {
    border-radius: 30px;
  }

  .text-right {
    text-align: right;
  }

  .border-theme-primary {
    border: 2px solid $theme-primary;

    &:after,
    &:before {
      color: $theme-primary;
    }
  }

  .border-theme-secondary {
    border: 2px solid $theme-primary;

    &:after,
    &:before {
      color: $theme-secondary;
    }
  }

  .quoter {
    font-size: 20px;
    font-weight: 700;
    margin: 30px auto;
    width: 90%;
  }
}

#certifications {
  padding-top: 0;
  padding-bottom: 50px;

  .flex-row {
    display: flex;
    align-items: center; /* Align items vertically to center */
    justify-content: space-between; /* Adjust as needed */
  }

  .cert-logo {
    max-height: 200px;
    margin: 0 auto;
    display: block;
    object-fit: cover;
  }

  .hitrust-logo {
    min-width: 150px;
    max-width: 250px;
  }

  @media (max-width: 800px) {
    .flex-row{
      display: unset;
      align-items: unset;
      justify-content: unset;
    }

    .cert-logo {
      margin-bottom: 50px;
    }

  }
}

#brands {
  .brand-logo {
    max-width: 200px;
    margin: 0 auto;
  }
}

.call-to-action {
  h2 {
    margin: 0 auto 20px;
  }
}

// Bootstrap Overrides
.text-primary {
  color: $theme-primary;
}

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

@media (max-width: 768px) {
  .img-responsive {
    max-width: 40% !important;
  }
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  background-color: unset;
  color: $theme-primary;
}

// Button Styles
.btn-default {
  @include button-variant($theme-dark, white, white);
}

.btn-primary {
  @include button-variant(white, $theme-primary, $theme-primary);
  margin: 24px 0;
}

.btn {
  @include sans-serif-font;
  border: none;
  border-radius: 300px;
  font-weight: 700;
  text-transform: uppercase;
}

.btn-xl {
  padding: 15px 30px;
}

// Contact
#contact {
  .fa {
    color: $theme-dark;
    font-size: 4em;

    .contact-attention {
      font-size: 1.5em;
      color: $theme-secondary;
    }
  }

  .no-underline {
    text-decoration: none !important;
    cursor: unset;
  }
}

// Extras
// -- Highlight Color Customization
::-moz-selection {
  color: white;
  text-shadow: none;
  background: $theme-dark;
}

::selection {
  color: white;
  text-shadow: none;
  background: $theme-dark;
}

img::selection {
  color: white;
  background: transparent;
}

img::-moz-selection {
  color: white;
  background: transparent;
}

body {
  -webkit-tap-highlight-color: $theme-dark;
}

.theme-primary {
  color: $theme-primary;
}

.theme-secondary {
  color: $theme-secondary;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.margin-bottom {
  margin-bottom: 50px;
}

.bold-text {
  font-weight: 700;
}

.heading-container {
  display: flex;
  align-items: baseline; /* Vertically center the items */
  justify-content: center; /* Horizontally center the items */
  gap: 20px; /* Add space between the image and the heading */
  flex-wrap: wrap; /* Allow the content to wrap when needed */
  text-align: center;

  .img-responsive {
    max-width: 30%;
    height: auto; /* Ensure the image scales responsively */
    padding-right: 10px;
    border-right: $theme-primary 1px solid;
  }

  .alt-img-responsive {
    max-width: 10%;
    height: auto; /* Ensure the image scales responsively */
    padding-right: 10px;
    border-right: white 1px solid;
  }

  .section-heading {
    font-size: 5rem !important;
  }

  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .leadership-container {
      flex-direction: column; /* Stack the image and heading on top of each other */
    }

    .section-heading {
      margin-top: 10px; /* Add some space between the image and the heading on small screens */
      font-size: 4rem !important;
    }
  }
}

// Back to Top Button Styles
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: white;
  color: $theme-primary;
  text-align: center;
  line-height: 50px;
  font-size: 24px;
  border-radius: 50%;
  text-decoration: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: darken($theme-primary, 10%);
    color: white;
    text-decoration: none;
  }
}

// Show the button when scrolling down
body.scrolled .back-to-top {
  opacity: 50%;
  visibility: visible;
}